import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { getFilteredContentData } from 'components/views/GrowerView/selectors';
import { getHivesDevelopmentData } from 'components/views/GrowerView/actions';
import { Tooltip, Legend, LineChart, XAxis, YAxis, Line, CartesianGrid } from 'recharts';
import { MIN_DISPLAY_DAYS } from 'components/constants';
import { getRanchData } from 'data';
import getDataForChart from './useDataForChart';
import '../ReportContainer.scss';

const CustomTooltip = ({ active, payload, country }) => {
    if (active && payload?.length) {
        const data = payload[0].payload;

        return (
            <div className="custom-tooltip">
                <p className="intro">Date: {data.date}</p>
                <p className="intro">Bee frames per hive: {data.beeFramesPerHive}</p>
                <p className="intro">Bee frame standard: {data.beeFrameStandard}</p>
                {country !== 'us' && (
                    <>
                        <p className="intro">Honey frames per hive: {data.honeyFramesPerHive}</p>
                        <p className="intro">Brood frames per hive: {data.broodFramesPerHive}</p>
                    </>
                )}
            </div>
        );
    }

    return null;
};

CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(PropTypes.shape()),
    country: PropTypes.string,
};

const LegendItem = ({ text, className }) => (
    <li className="text">
        <span className={cn('legend-icon orange-icon', className)} />
        {text}
    </li>
);

LegendItem.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
};

const CustomLegend = ({ country }) => (
    <div className="custom-legend">
        <div className="custom-legend-block">
            <ul className="custom-legend-list">
                <LegendItem text="Bee frames per hive" className="orange-icon" />
                <LegendItem text="Bee frame standard" className="blue-icon" />
            </ul>
            {country !== 'us' && (
                <ul className="custom-legend-list">
                    <LegendItem text="Honey frames per hive" className="brown-icon" />
                    <LegendItem text="Brood frames per hive" className="green-icon" />
                </ul>
            )}
        </div>
    </div>
);

CustomLegend.propTypes = {
    country: PropTypes.string,
};

const margin = {
    top: 20,
    right: 30,
    left: 20,
    bottom: 5,
};

const HiveDevelopment = ({ ranch, startDate, endDate, isHardcodedData }) => {
    const dispatch = useDispatch();
    const ranchContentDataDb = useSelector(getFilteredContentData, arrayOfObjectsShallowEqual);

    const ranchContentData = isHardcodedData ? getRanchData(ranch.id) : ranchContentDataDb;

    const chartData = getDataForChart(ranchContentData);

    useEffect(() => {
        dispatch(getHivesDevelopmentData(ranch?.id, startDate, endDate));
    }, [dispatch, endDate, ranch.id, startDate]);

    if (!chartData?.length || chartData?.length < MIN_DISPLAY_DAYS) {
        return null;
    }

    return (
        <div className="report-container">
            <h3 className="header">Hives Development</h3>
            <LineChart width={878} height={300} data={chartData} margin={margin}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis domain={[2, 14]} ticks={[2, 4, 6, 8, 10, 12]} />
                <Tooltip content={<CustomTooltip country={ranch?.country} />} />
                <Legend content={<CustomLegend country={ranch?.country} />} />
                {/* For USA, currently it’ll show just bee frame and bee frame standard */}
                <Line
                    type="monotone"
                    dataKey="beeFramesPerHive"
                    stroke="#FDBA12"
                    name="Bee frames per hive"
                    dot={false}
                    strokeWidth={2}
                />
                <Line
                    type="monotone"
                    dataKey="beeFrameStandard"
                    stroke="#4392F1"
                    name="Bee frame standard"
                    dot={false}
                    strokeDasharray="5 5"
                    strokeWidth={2}
                />
                {ranch?.country !== 'us' && (
                    <>
                        <Line
                            type="monotone"
                            dataKey="honeyFramesPerHive"
                            stroke="#BF6C27"
                            name="Honey frames per hive"
                            dot={false}
                            strokeWidth={2}
                        />
                        <Line
                            type="monotone"
                            dataKey="broodFramesPerHive"
                            stroke="#43976A"
                            name="Brood frames per hive"
                            dot={false}
                            strokeWidth={2}
                        />
                    </>
                )}
            </LineChart>
        </div>
    );
};

HiveDevelopment.propTypes = {
    ranch: PropTypes.shape({
        id: PropTypes.string,
        country: PropTypes.string,
    }),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    isHardcodedData: PropTypes.bool,
};

export default memo(HiveDevelopment);
