import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useFetch from 'utils/hooks/useFetch';
import useHeatMap from './useHeatMap';
import HeatMapToggle from './HeatMapToggle';
import HeatMapLegend from './HeatMapLegend';
import HeatMapTimeframes from './HeatMapTimeframes';
import { fetchHeatMap, FETCH_HEAT_MAP } from './actions';

const HeatMap = ({ map, zoom, ranchId, data }) => {
    const { isHeatMapShown, toggleHeatMapShown, timeframeIndex, setTimeframeIndex, timeframes, isFeatureAvailable } =
        useHeatMap({
            map,
            zoom,
            ranchId,
            data,
        });

    if (!isFeatureAvailable) return null;
    return (
        <>
            <HeatMapToggle isHeatMapShown={isHeatMapShown} setIsHeatMapShown={toggleHeatMapShown} />
            <HeatMapLegend />
            <HeatMapTimeframes
                timeframes={timeframes}
                setTimeframeIndex={setTimeframeIndex}
                timeframeIndex={timeframeIndex}
            />
        </>
    );
};

const HeatMapContainer = ({ zoom, ranchId, map }) => {
    const params = useMemo(() => (ranchId ? { ranchId } : null), [ranchId]);
    const { data } = useFetch({ action: fetchHeatMap, params });
    const isLoading = useSelector(state => state.ui.loading[FETCH_HEAT_MAP.default]);
    if (!data?.points || isLoading) return null;
    return <HeatMap map={map} zoom={zoom} ranchId={ranchId} data={data} />;
};

HeatMap.propTypes = {
    map: PropTypes.shape(),
    zoom: PropTypes.number,
    ranchId: PropTypes.string,
    data: PropTypes.shape(),
};

HeatMapContainer.propTypes = {
    zoom: PropTypes.number,
    ranchId: PropTypes.string,
    map: PropTypes.shape(),
};

export default HeatMapContainer;
