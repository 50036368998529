import React from 'react';
import PropTypes from 'prop-types';
import MapInfoWrapper from '../../MapInfo/MapInfoWrapper';
import './HeatMapTimeframes.scss';

const HeatMapTimeframes = ({ timeframes, setTimeframeIndex, timeframeIndex }) => (
    <MapInfoWrapper className="heat-map-timeframes">
        {timeframes?.map((timeframe, index) => (
            <span
                key={timeframe}
                onClick={() => setTimeframeIndex(index)}
                role="presentation"
                className={index === timeframeIndex ? 'selected' : ''}
            >
                {timeframe}
            </span>
        ))}
    </MapInfoWrapper>
);

HeatMapTimeframes.propTypes = {
    timeframes: PropTypes.arrayOf(PropTypes.string),
    setTimeframeIndex: PropTypes.func,
    timeframeIndex: PropTypes.number,
};

export default HeatMapTimeframes;
