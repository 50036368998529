import constants from 'appConstants';

const {
    REPORTS: { CORRECTION_FACTOR },
} = constants;

export const EXTRA_FRAMES = 2;

export const calculateCurrentAverageBeeFramesPerHive = framesData => {
    if (!framesData || !Object.keys(framesData)?.length) {
        return 0;
    }
    const numbers = Object.values(framesData).flat();
    if (!numbers.length) return 0;
    const sum = numbers.reduce((acc, val) => acc + val, 0);
    return ((sum / numbers.length) * CORRECTION_FACTOR).toFixed(2);
};

export const calculateHivesCorrection = (framesData, ranch) => {
    if (!framesData || !Object.keys(framesData)?.length) {
        return 0;
    }
    const beeFramesAmountAgg = Object.values(framesData).flat();
    const weakFrames = beeFramesAmountAgg?.filter(beeFrames => beeFrames * CORRECTION_FACTOR < 6);
    if (!weakFrames.length) {
        return 0;
    }
    const totalHivesPerBhomeDrops = ranch.totalPlacedHives - ranch.totalPlacedStandardHives;
    const weakFramesRatio = weakFrames.length / beeFramesAmountAgg.length;
    const correctedTotalAmount = (totalHivesPerBhomeDrops * weakFramesRatio).toFixed();
    const weakFramesPercent = (weakFramesRatio * 100).toFixed();
    return `${correctedTotalAmount} (${weakFramesPercent}%)`;
};
