import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useFetch = ({ action, params }) => {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (!params) return;
        dispatch(
            action({
                ...params,
                resolver: setData,
                rejector: error => {
                    setError(error);
                    setData(null);
                },
            })
        );
    }, [action, params, dispatch, setData, setError]);

    return { error, data };
};

export default useFetch;
