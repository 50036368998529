import dayjs from 'dayjs';
import constants from 'appConstants';

const {
    REPORTS: { CORRECTION_FACTOR },
} = constants;

const getDataForChart = dataModel =>
    dataModel.map(entry => ({
        date: dayjs(entry.date).format('MM/DD'),
        beeFramesPerHive: entry.beeFramesPerHive * CORRECTION_FACTOR,
        beeFrameStandard: entry.beeFrameStandard,
        broodFramesPerHive: entry.broodFramesPerHive,
        honeyFramesPerHive: entry.honeyFramesPerHive,
    }));
export default getDataForChart;
