import React from 'react';
import Toogle from '@beewise/toggle';
import PropTypes from 'prop-types';
import './HeatMapToggle.scss';
import MapInfoWrapper from '../../MapInfo/MapInfoWrapper';

const HeatMapToggle = ({ isHeatMapShown, setIsHeatMapShown }) => (
    <MapInfoWrapper className="heat-map-toggle">
        <Toogle value={isHeatMapShown} onChange={() => setIsHeatMapShown(!isHeatMapShown)} label="Bee visualization" />
    </MapInfoWrapper>
);

HeatMapToggle.propTypes = {
    isHeatMapShown: PropTypes.bool.isRequired,
    setIsHeatMapShown: PropTypes.func.isRequired,
};

export default HeatMapToggle;
