import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Sector, Text } from 'recharts';
import '../ReportContainer.scss';

const getChartData = percentage => [
    { name: 'Completed', value: percentage },
    { name: 'Remaining', value: 100 - percentage },
    { name: 'All', value: 0 },
];

const CustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
    const x = cx + radius * Math.cos((-midAngle * Math.PI) / 180);
    const y = cy + radius * Math.sin((-midAngle * Math.PI) / 180);

    const formattedPercent = percent === 0 ? '100%' : `${(percent * 100).toFixed()}%`;

    return (
        <Text x={x} y={y} fill="#626262" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {name !== 'Remaining' ? formattedPercent : ''}
        </Text>
    );
};

CustomLabel.propTypes = {
    cx: PropTypes.number,
    cy: PropTypes.number,
    midAngle: PropTypes.number,
    innerRadius: PropTypes.number,
    outerRadius: PropTypes.number,
    percent: PropTypes.number,
    name: PropTypes.string,
};

const renderActiveShape = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill }) => (
    <g>
        <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
        />
    </g>
);

const SeasonProgressMeter = ({ startDate, endDate }) => {
    const currentDate = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    let percentage;
    if (currentDate <= start) {
        percentage = 0;
    } else if (currentDate >= end) {
        percentage = 100;
    } else {
        const totalDuration = end - start;
        const passedDuration = currentDate - start;
        percentage = Math.round((passedDuration / totalDuration) * 100);
    }

    const chartData = getChartData(percentage);

    return (
        <div className="report-container">
            <h3 className="header">Season Progress Meter</h3>
            <p className="description">Achieved {percentage}% pollination for ranch carry capacity</p>
            <PieChart width={400} height={230}>
                <Pie
                    data={chartData}
                    cx="50%"
                    cy="100%"
                    startAngle={180}
                    endAngle={0}
                    innerRadius={0}
                    outerRadius={150}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="value"
                    activeIndex={0}
                    activeShape={renderActiveShape}
                    labelLine={false}
                    label={CustomLabel}
                >
                    {chartData.map((entry, index) => (
                        <Cell key={entry.name} fill={index ? '#EEEEEE' : '#7BBB6B'} />
                    ))}
                </Pie>
            </PieChart>
        </div>
    );
};

SeasonProgressMeter.propTypes = {
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
};

export default memo(SeasonProgressMeter);
