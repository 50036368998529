import dayjs from 'dayjs';
import {
    FETCH_RANCHES,
    FETCH_USER,
    FETCH_FRAMES_PER_HIVE,
    FETCH_RANCH_APPROVAL,
    SET_RANCH_APPROVAL,
    FETCH_BEE_ACTIVITIES_OVER_TIME,
    FETCH_HIVE_DEVELOPMENT,
    SET_SELECTED_RANCH_ID,
    FETCH_RANCH_BY_ID,
} from '../actionTypes';

export const fetchRanches = (isGrower, resolver) => {
    const url = isGrower ? '/ranches/v2/grower' : '/ranches/v2';
    return {
        type: 'FETCH',
        params: {
            type: FETCH_RANCHES.default,
            url,
            resolver,
        },
    };
};

export const fetchUser = (userId, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_USER.default,
        url: `/users/${userId}`,
        failureToastText: 'Loading ranch support contact error',
        resolver,
    },
});

export const fetchFramesPerHive = (ranchId, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_FRAMES_PER_HIVE.default,
        url: `/ranches/v2/${ranchId}/bhomes/frames-per-hive`,
        failureToastText: 'Loading frames per hive data error',
        resolver,
    },
});

export const fetchBeeActivitiesOverTime = ({ ranchId, startDate, endDate, resolver }) => {
    const currentDate = dayjs().format('YYYY-MM-DD');
    const adjustedEndDate = dayjs(endDate).isAfter(dayjs()) ? currentDate : endDate;

    return {
        type: 'FETCH',
        params: {
            type: FETCH_BEE_ACTIVITIES_OVER_TIME.default,
            url: `/ranches/v2/${ranchId}/bee-activities?startDate=${startDate}&endDate=${adjustedEndDate}`,
            failureToastText: 'Loading bee activity data error',
            resolver,
        },
    };
};

export const fetchRanchApproval = id => ({
    type: 'FETCH',
    params: {
        method: 'GET',
        type: FETCH_RANCH_APPROVAL.default,
        url: `/ranches/v2/${id}/approvals`,
    },
});

export const setRanchApproval = ({ ranchId, approvalId, body, file, resolver, rejector }) => ({
    type: 'FETCH',
    params: {
        method: 'PATCH',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        type: SET_RANCH_APPROVAL.default,
        url: `/ranches/v2/${ranchId}/approvals/${approvalId}`,
        body,
        file,
        failureToastText: 'Error updating the drop layout status',
        toastText: 'Drop layout status successfully updated',
        resolver,
        rejector,
    },
});

export const getHivesDevelopmentData = (id, startDate, endDate) => {
    const requestBody = {
        attr: ['beeFramesPerHive', 'honeyFramesPerHive', 'broodFramesPerHive'],
        startDate,
        endDate,
    };

    return {
        type: 'FETCH',
        params: {
            method: 'POST',
            type: FETCH_HIVE_DEVELOPMENT.default,
            url: `/ranches/v2/${id}/bhomes/hives-development`,
            body: requestBody,
        },
    };
};

export const fetchRanchById = id => ({
    type: 'FETCH',
    params: {
        method: 'GET',
        type: FETCH_RANCH_BY_ID.default,
        url: `/ranches/v2/${id}`,
    },
});

export const setSelectedRanchId = id => ({
    type: SET_SELECTED_RANCH_ID,
    item: id,
});
