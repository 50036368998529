import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Tooltip from '@beewise/tooltip';
import Menu from '@beewise/menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { COUNTRY_IL, COUNTRY_US } from 'utils/isIsraeliRanch';
import { pluralizeNoun } from 'utils/pluralizeNoun';
import { modalController } from 'components/reusables/ModalManager/modalController';
import { MODALS } from 'components/reusables/ModalManager/config';
import { getSelectedBrokerFilter, getIsRanchHovered, getIsRanchSelected } from 'components/views/BrokerView/selectors';
import {
    fetchRanches,
    updateRanchStatus,
    deleteRanchById,
    requestRanchApproval,
    sendSmsWithCoordinates,
} from 'components/views/BrokerView/actions';
import { useMapContext } from 'components/views/BrokerView/views/Dashboard/context/MapContext';
import { getLoadingZoneCoordinates } from 'components/views/BrokerView/views/Dashboard/utils';
import constants from 'appConstants';
import { RanchType } from '../types';
import SelectBadge from './SelectBadge';
import { RANCH_STATUSES, STATUSES } from '../../../../utils';
import StatusOptionName from './StatusOptionName';
import './RanchItem.scss';

const getStatusOption = ({ name, title, value, activeStatus }) => (
    <div
        className={cx('select-option-status', {
            disabled: value === RANCH_STATUSES.draft,
            active: value === activeStatus,
        })}
    >
        <div className={cx('status-icon', 'status-icon-option', `status-icon--${value}`)} />
        <StatusOptionName name={name} title={title} />
    </div>
);

export const getStatusesOptions = ({ activeStatus }) =>
    Object.entries(STATUSES).map(([key, { name, title }]) => ({
        label: getStatusOption({ name, title, activeStatus, value: key }),
        value: key,
    }));

const getSelectIcon = status => <div className={cx('status-icon', `status-icon--${status}`)} />;

const RanchItem = ({ data: { filteredRanches, handleSelectRanch }, index, style }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const ranch = filteredRanches[index];
    const isActiveRanch = useSelector(state => getIsRanchSelected(state, ranch?.id));
    const isHoveredRanch = useSelector(state => getIsRanchHovered(state, ranch?.id));
    const selectedBrokerFilter = useSelector(getSelectedBrokerFilter);
    const { handleRanchHover, handleRanchHoverEnd } = useMapContext();

    if (!ranch) {
        return null;
    }

    const {
        name,
        requiredHivesAmount = 0,
        totalPlannedHives = 0,
        totalAssignedHives = 0,
        totalPlacedHives = 0,
        status,
        id,
    } = ranch;

    const ranchDetails = [
        { label: 'Contracted:', value: requiredHivesAmount, showCheckmark: false },
        {
            label: 'Planned:',
            value: totalPlannedHives,
            showCheckmark: totalPlannedHives === requiredHivesAmount,
            isRedData: totalPlannedHives > requiredHivesAmount,
            tooltipMessage: (
                <div className="tooltip-left-align">
                    Planned {totalPlannedHives - requiredHivesAmount}{' '}
                    {pluralizeNoun(totalPlannedHives - requiredHivesAmount, 'hive')}
                    <br />
                    more than contracted
                </div>
            ),
        },
        { label: 'Assigned:', value: totalAssignedHives, showCheckmark: totalAssignedHives === requiredHivesAmount },
        {
            label: 'Placed:',
            value: totalPlacedHives,
            showCheckmark: totalPlacedHives === requiredHivesAmount,
            isRedData: totalPlacedHives > requiredHivesAmount,
            tooltipMessage: (
                <div className="tooltip-left-align">
                    {totalPlacedHives - requiredHivesAmount}{' '}
                    {pluralizeNoun(totalPlacedHives - requiredHivesAmount, 'hive')} were placed in a
                    <br />
                    different location then planned
                </div>
            ),
        },
    ];

    const handleSetCurrentRanch = () => handleSelectRanch({ ranchId: ranch.id, shouldScroll: false });

    const handleStatusChange = newStatus =>
        dispatch(
            updateRanchStatus({
                id: ranch.id,
                status: newStatus,
                resolver: () => {
                    if ([COUNTRY_US, COUNTRY_IL].includes(selectedBrokerFilter)) {
                        dispatch(fetchRanches({ country: selectedBrokerFilter }));
                    } else {
                        dispatch(fetchRanches({ brokerId: selectedBrokerFilter }));
                    }
                    handleSetCurrentRanch();
                },
            })
        );

    const handleMouseEnter = () => handleRanchHover({ ranchId: ranch.id });
    const handleMouseLeave = () => handleRanchHoverEnd({ ranchId: ranch.id });

    const threeDotMenu = [
        {
            label: 'Grower dashboard',
            onClick: () => window.open(`/report/${id}`, '_blank'),
        },
        {
            label: 'Send location',
            onClick: () =>
                modalController.set({
                    name: MODALS.SEND_RANCH_LOCATION,
                    props: {
                        onConfirm: ({ contactName, phoneNumber }) => {
                            const coordinates = getLoadingZoneCoordinates({ ranch });
                            dispatch(sendSmsWithCoordinates({ contactName, phoneNumber, coordinates }));
                        },
                    },
                }),
            isDisabled: true,
        },
        {
            label: 'Layout approval',
            onClick: () => dispatch(requestRanchApproval(id)),
            ...(ranch?.approvalStatus
                ? {
                      tooltip: `Approval is in status: ${
                          ranch?.approvalStatus || constants.RANCH_APPROVAL_STATUSES.REQUESTED
                      }`,
                  }
                : {}),
            isDisabled: true,
        },
        {
            label: 'Delete ranch',
            onClick: () =>
                modalController.set({
                    name: MODALS.DELETE_RANCH,
                    props: {
                        onConfirm: () => dispatch(deleteRanchById(id)),
                        onClose: () => navigate('/'),
                        ranchName: name,
                    },
                }),
            className: 'red-item',
        },
    ];

    return (
        <div
            style={style}
            className="ranch-item"
            onClick={handleSetCurrentRanch}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            role="presentation"
        >
            <div
                className={cx('ranch-item-content', { 'active-ranch': isActiveRanch, 'hovered-ranch': isHoveredRanch })}
            >
                <div className="ranch-item-content-top">
                    <div>{name}</div>
                    <div className="ranch-item-content-top-right">
                        <SelectBadge
                            options={getStatusesOptions({ activeStatus: status })}
                            size="xsmall"
                            icon={getSelectIcon(STATUSES[status]?.name?.toLowerCase())}
                            name={STATUSES[status]?.name}
                            onChange={handleStatusChange}
                        />
                        {(isHoveredRanch || isActiveRanch) && (
                            <Menu
                                className="ranch-item-menu"
                                options={threeDotMenu}
                                position="bottom right"
                                iconType="secondary"
                                style={{
                                    position: 'absolute',
                                    top: '8px',
                                    right: '8px',
                                    zIndex: 10,
                                }}
                            />
                        )}
                    </div>
                </div>
                <div className="ranch-item-content-bottom">
                    {ranchDetails.map(({ label, value, showCheckmark, isRedData, tooltipMessage }, index) => (
                        <div className="ranch-item-content-bottom-item" key={`${label}-${index}`}>
                            <span>{label}</span>
                            <Tooltip
                                text={isRedData ? tooltipMessage : ''}
                                disabled={!isRedData}
                                inverted
                                position="top"
                            >
                                <div className={cx('ranch-item-content-bottom-item-number', { 'red-data': isRedData })}>
                                    {value}
                                </div>
                            </Tooltip>
                            {showCheckmark && <FontAwesomeIcon icon={faCheck} />}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

RanchItem.propTypes = {
    data: PropTypes.shape({
        filteredRanches: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape(RanchType)), PropTypes.array]),
        handleSelectRanch: PropTypes.func.isRequired,
    }).isRequired,
    index: PropTypes.number.isRequired,
    style: PropTypes.shape({}).isRequired,
};

export default RanchItem;
