import { useMemo, useEffect, useState } from 'react';
import { useToggle } from '@beewise/react-utils';

const getGooglePoint = timeframe => point =>
    window.google && {
        location: new window.google.maps.LatLng(point.lat, point.lng),
        weight: point[timeframe],
    };

const DEFAULT_GRADIENT = [
    'rgba(255, 0, 0, 0)', // Transparent (0% - no intensity)
    'rgba(255, 0, 0, 1)', // Red (starts at low intensity)
    'rgba(255, 128, 0, 1)', // Orange-Red
    'rgba(255, 255, 0, 1)', // Yellow
    'rgba(128, 255, 0, 1)', // Light Green
    'rgba(0, 255, 0, 1)', // Medium Green
    'rgba(0, 128, 0, 1)', // 100%
];

const MIN_ZOOM = 14;

const DEFAUT_ZOOM_RADIUS = {
    [MIN_ZOOM]: 13,
    15: 30,
    16: 50, // good
    17: 100,
    18: 200, // Most zoomed in
    19: 200,
    20: 300,
};

const useHeatMap = ({ map, zoom, data }) => {
    const [isHeatMapShown, toggleHeatMapShown] = useToggle(false);
    const [timeframeIndex, setTimeframeIndex] = useState(0);
    const timeframe = data?.timeframes?.[timeframeIndex];

    const zoomRadius = data?.settings?.ZOOM_RADIUS ?? DEFAUT_ZOOM_RADIUS;
    const gradient = data?.settings?.GRADIENT ?? DEFAULT_GRADIENT;

    const googlePoints = useMemo(
        () => map && data?.points?.map(getGooglePoint(timeframe)),
        [data?.points, map, timeframe]
    );
    const isFeatureAvailable = googlePoints?.length && zoom >= MIN_ZOOM;
    const isShown = isHeatMapShown && isFeatureAvailable;
    const heatMap = useMemo(
        () =>
            map &&
            window.google?.maps?.visualization &&
            googlePoints &&
            new window.google.maps.visualization.HeatmapLayer({
                data: googlePoints,
                gradient,
                maxIntensity: 1,
                opacity: 0.7,
                radius: zoomRadius[zoom],
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [googlePoints, map, gradient, zoomRadius]
    );
    useEffect(() => {
        if (!map || !googlePoints || !heatMap) return;
        if (!isShown) {
            heatMap?.setMap(null);
            return;
        }
        heatMap.setMap(map);
        return () => {
            heatMap?.setMap(null);
        };
    }, [map, googlePoints, isShown, heatMap]);

    useEffect(() => {
        if (!heatMap || !isShown) return;
        heatMap?.set('radius', zoomRadius[zoom]);
    }, [zoom, isShown, heatMap, zoomRadius]);

    const handleTimeframeChange = index => {
        heatMap?.setMap(null);
        setTimeframeIndex(index);
    };

    return {
        isHeatMapShown,
        isFeatureAvailable,
        toggleHeatMapShown,
        setTimeframeIndex: handleTimeframeChange,
        timeframeIndex,
        timeframes: data?.timeframes,
    };
};

export default useHeatMap;
