import {
    faDrawSquare,
    faLocationPlus,
    faMapLocationDot,
    faCircleMinus,
    faRoute,
    faPenLine,
    faDungeon,
    faRoadBarrier,
    faNote,
    faForklift,
    faHand,
} from '@fortawesome/pro-light-svg-icons';
import constants from 'appConstants';

export const TOOLBAR_MODES = {
    NEUTRAL_CURSOR: {
        icon: faHand,
        title: 'neutral_cursor',
        tooltip: 'Neutral cursor',
    },
    DRAW_POLYGON: {
        icon: faDrawSquare,
        title: 'draw_polygon',
        tooltip: 'Draw polygon',
    },
    EDIT_POLYGON: {
        icon: faPenLine,
        title: 'edit_polygon',
        tooltip: 'Edit polygon',
    },
    CLEAR_PATH: {
        icon: faCircleMinus,
        title: 'clear_path',
        tooltip: 'Clear path',
    },
    CREATE_LOCATION: {
        icon: faLocationPlus,
        title: 'create_location',
        tooltip: 'Place a drop',
    },
    AUTOPLACE: {
        icon: faMapLocationDot,
        title: 'autoplace',
        tooltip: 'Autoplace',
    },
    ADD_LABEL: {
        icon: 'label',
        title: 'add_label',
        tooltip: 'Add label',
        pinType: constants.PIN_TYPES.LABEL,
    },
    ADD_NOTE: {
        icon: faNote,
        title: 'add_note',
        tooltip: 'Add note',
        pinType: constants.PIN_TYPES.NOTE,
    },
    DRAW_ROAD: {
        icon: faRoute,
        title: 'draw_road',
        tooltip: 'Mark access road',
    },
    ADD_ROAD_BLOCK: {
        icon: faRoadBarrier,
        title: 'add_road_block',
        tooltip: 'Add road block',
        pinType: constants.PIN_TYPES.ROAD_BLOCK,
    },
    ADD_GATE: {
        icon: faDungeon,
        title: 'add_gate',
        tooltip: 'Add gate',
        pinType: constants.PIN_TYPES.GATE,
    },
    MARK_LOADING_ZONE: {
        icon: faForklift,
        title: 'mark_loading_zone',
        tooltip: 'Mark loading zone',
        pinType: constants.PIN_TYPES.LOADING_ZONE,
    },
};

export const TOOLBAR_MODE_PINS = {
    [TOOLBAR_MODES.ADD_GATE.title]: constants.PIN_TYPES.GATE,
    [TOOLBAR_MODES.ADD_ROAD_BLOCK.title]: constants.PIN_TYPES.ROAD_BLOCK,
    [TOOLBAR_MODES.ADD_NOTE.title]: constants.PIN_TYPES.NOTE,
};
